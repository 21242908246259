import React, { useCallback, useEffect, useState } from 'react';
import EnhancedTable from '../../../components/common/Table';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import empty from '../../../assets/images/empty.png';
import Button from '../../../components/common/Button';
import { useHistory } from 'react-router-dom';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import 'survey-core/defaultV2.min.css';
import surveyThemeJson from '../../../utils/surveyThemeJson';
import SurveyAPI from '../../AnnouncementContainer/survey-api';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import Alert from '../../../components/common/Alert';
import { exportExcell } from '../../../utils/reports';

import SurveyModal from '../../../components/common/Modal/SurveyModal';

const SurveyTable = ({ project }) => {
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const [surveyId, setSurveyId] = useState(null);
  const [messageAlert, setMessageAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (loading) {
      try {
        const response = await SurveyAPI.getSurveys(project.announcementId);
        const surveysData = response.data.surveys;

        setSurveys(surveysData);
      } catch (error) {
        const errorMessage =
          error.response.data?.message || 'Error al obtener las encuestas.';
        setMessageAlert(errorMessage);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const downloadReport = async (survey) => {
    try {
      const response = await SurveyAPI.reportCoursesByAnnouncement(survey.id);
      const objects = response.data.objects;

      exportExcell(objects, survey.name);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        'Error al descargar el informe de cursos.';
      setMessageAlert(errorMessage);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const previewSurvey = async (original) => {
    try {
      const { data } = await SurveyAPI.getSurveyByUid(original.uid);
      const jsonData = JSON.parse(data.jsonData);

      setSelectedSurvey(jsonData);
      setSurveyId(original.id);
      setIsModalOpen(true);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || 'Error al obtener la encuesta.';
      setMessageAlert(errorMessage);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const onEdit = (original) => {
    setSelectedSurvey(original);
    setIsEditModalOpen(true);
  };

  const onMenuClick = (id) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [id]: !prevOpenMenus[id],
    }));
  };

  const closeModal = () => {
    setSelectedSurvey(null);
    setSurveyId(null);
    setIsModalOpen(false);
  };

  const closeEditModal = () => {
    setSelectedSurvey(null);
    setIsEditModalOpen(false);
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'Fecha de activación', accessor: 'date' },
      {
        Header: 'Dirigido a',
        accessor: 'role',
        Cell: ({ value }) => (value === 'both' ? 'Ambos' : value),
      },
      { Header: 'Tipo de encuesta', accessor: 'typeSurvey' },
      { Header: 'Número de preguntas', accessor: 'numberQuestions' },
      { Header: 'Estado', accessor: 'status' },
      {
        Header: 'Acción',
        Cell: ({ row: { original } }) => (
          <MenuContainer onClick={() => onMenuClick(original.id)}>
            <Menu
              open={openMenus[original.id]}
              items={[
                {
                  text: 'Preview',
                  action: () => previewSurvey(original),
                },
                {
                  text: 'Editar',
                  action: () => onEdit(original),
                },
                {
                  text: 'Descargar PDF',
                  action: () => downloadReport(original),
                },
              ]}
            />
          </MenuContainer>
        ),
      },
    ],
    [openMenus, downloadReport]
  );

  return (
    <>
      {showAlert && <Alert type="error">{messageAlert}</Alert>}
      <div className="container-btn-survey">
        <Button key="reloadSurveyButton" secondary style={{ width: '202px' }}>
          Actualizar encuestas
        </Button>
        ,
        <Button
          onClick={() => history.push('/convocatorias/agregar')}
          key="newSurveyButton"
          style={{ width: '202px' }}
        >
          <span className="material-icons">add</span>
          Nueva encuesta
        </Button>
        ,
      </div>
      <EnhancedTable
        isLoadingData={loading}
        data={surveys}
        columns={columns}
        tableName="Encuestas"
        haveRowSelect={true}
      />
      {/* {loading || surveys.length ? (
        <>
          <EnhancedTable
            isLoadingData={loading}
            data={surveys}
            columns={columns}
            tableName="Encuestas"
            actions={[
              <Button
                key="newParticipantButton"
                onClick={() => history.push('/convocatorias/agregar')}
              >
                <span className="material-icons">add</span>
                Nuevo Participante
              </Button>,
            ]}
          />

          {selectedSurvey && (
            <Modal
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal__scrollable"
            >
              <SurveyTableComponent
                surveyQuestions={selectedSurvey}
                onComplete={() => {}}
                isReadOnly={true}
              />
            </Modal>
          )}
          {isEditModalOpen && (
            <SurveyModal
              modalOpen={isEditModalOpen}
              announcementId={project}
              setIsSurveyModalOpen={setIsEditModalOpen}
              survey={selectedSurvey}
              onClose={closeEditModal}
              isEditableSource={true}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      ) : (
        <div className="empty-tab">
          <EmptyCard
            message="Actualmente no existen cursos registrados"
            img={empty}
          />
        </div>
      )} */}
    </>
  );
};

const SurveyTableComponent = ({ surveyQuestions, isReadOnly }) => {
  const myCss = {
    title: 'sv-title',
    description: 'sv-description',
    navigation: {
      complete: 'btn-complete',
    },
  };

  useEffect(() => {
    const survey = new Model(surveyQuestions);
    survey.applyTheme(surveyThemeJson);
    survey.completeText = 'Enviar';
    survey.widthMode = 'responsive';
    survey.css = myCss;

    if (isReadOnly) {
      survey.mode = 'display';
    }

    setSurveyModel(survey);
  }, [surveyQuestions, isReadOnly]);

  const [surveyModel, setSurveyModel] = useState(null);

  const surveyContainerStyle = {
    width: '80%',
    height: '95%',
    padding: '10px',
    margin: 'auto',
  };

  return (
    <div className="survey-container" style={surveyContainerStyle}>
      {surveyModel && <Survey model={surveyModel} />}
    </div>
  );
};

SurveyTableComponent.propTypes = {
  surveyQuestions: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

SurveyTableComponent.defaultProps = {
  isReadOnly: false,
};

export default SurveyTable;
