import React, { useEffect, useState } from 'react';

import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../common/Button';
import ButtonMui from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from './searchBox';
import { TablePagination } from '@material-ui/core';
import RangeDatePicker from '../RangeDatePicker';

// Create an editable cell renderer
const LIMIT_PER_PAGE = 25;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setPage,
  onSearch,
  style,
}) {
  const count = preGlobalFilteredRows.length;

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 600);

  return (
    <span>
      <Search
        style={style}
        value={value}
        onChange={(e) => {
          if (onSearch) {
            onSearch(e);
            setPage(0);
          } else {
            setValue(e.target.value);
            onChange(e.target.value);
            setPage(0);
          }
        }}
      />
    </span>
  );
}

const EnhancedTable = ({
  columns,
  data,
  tableName,
  loader,
  updateMyData,
  skipPageReset,
  onClickButton,
  buttonLabel,
  isLoadingData,
  //for footer table
  currentPage,
  onChangePage,
  totalPages,
  onSearch,
  style,
  //isDynamic if brings pages from back
  isDynamic = false,
  haveRowSelect = false,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    isDynamic ? data.length : LIMIT_PER_PAGE
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const [rowSelected, setRowSelected] = useState([]);
  const isSelected = (name) => rowSelected.indexOf(name) !== -1;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setRowSelected(newSelecteds);
      return;
    }
    setRowSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = rowSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowSelected.slice(1));
    } else if (selectedIndex === rowSelected.length - 1) {
      newSelected = newSelected.concat(rowSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowSelected.slice(0, selectedIndex),
        rowSelected.slice(selectedIndex + 1)
      );
    }

    setRowSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.error': {
        backgroundColor: '#FFF3F2',
      },
      '&.info': {
        backgroundColor: '#F1FCFF',
      },
      '&.success': {
        backgroundColor: '#EBFDF0',
      },
      '&.warning': {
        backgroundColor: '#FFFBEE',
      },
      '&.default': {
        backgroundColor: '#FFFFFF',
      },
    },
  }))(TableRow);

  return (
    <>
      <TableContainer className="table-container">
        <div className="actions-table">
          <h1 className="actions-table__title">{tableName}</h1>
          <div className="actions-table__action-container">
            {tableName === 'Encuestas' && (
              <ButtonMui
                variant="text"
                disabled={rowSelected.length === 0}
                className={`__btnLink ${
                  rowSelected.length === 0 && 'disabled'
                }`}
              >
                Relacionar encuestas
              </ButtonMui>
            )}
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              setPage={setPage}
              onSearch={onSearch}
              style={style}
            />
            {tableName === 'Encuestas' && (
              <RangeDatePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}

            {loader && <CircularProgress color="secondary" />}
            {onClickButton && (
              <Button
                className="actions-table__button button"
                type={'button'}
                onClick={onClickButton}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </div>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {haveRowSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        rowSelected.length > 0 &&
                        rowSelected.length < rows.length
                      }
                      checked={
                        rows.length > 0 && rowSelected.length === rows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </TableCell>
                )}
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...(column.id === 'selection'
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                    >
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoadingData
              ? [1, 2, 3, 4, 5].map((number, index) => (
                  <TableRow
                    key={(
                      number + Math.floor(Math.random() * 120 * 1022222)
                    ).toString()}
                  >
                    {headerGroups.map((headerGroup) =>
                      headerGroup.headers.map(() => (
                        <TableCell
                          key={Math.floor(
                            Math.random() * 120 * Math.random() * 120
                          ).toString()}
                        >
                          <Skeleton />
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                ))
              : isDynamic
              ? rows.map((row, i) => {
                  prepareRow(row);
                  const isItemSelected = isSelected(row.name);
                  return (
                    <StyledTableRow
                      className={row.original?.bgColor}
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      {...row.getRowProps()}
                    >
                      {haveRowSelect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {row.cells.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })
              : rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    prepareRow(row);
                    return (
                      <StyledTableRow
                        className={row.original?.bgColor}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
            <TableRow>
              <TablePagination
                labelRowsPerPage="" //"Filas por página:"
                rowsPerPageOptions={[]} //{[5, 10, 25]}
                count={isDynamic ? totalPages : rows.length}
                labelDisplayedRows={(sth) => {
                  return isDynamic
                    ? `${currentPage} página de ${totalPages} ${
                        totalPages < 1 ? 'página' : 'páginas'
                      } `
                    : `${sth.from}-${sth.to} de ${
                        sth.count !== -1 ? sth.count : `more than ${sth.to}`
                      }`;
                }}
                rowsPerPage={rowsPerPage}
                page={isDynamic ? currentPage : page}
                onPageChange={(e, newPage) => {
                  onChangePage
                    ? onChangePage(e, newPage)
                    : handleChangePage(e, newPage);
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool,
};

export default EnhancedTable;
