import React, { useEffect, useMemo, useState } from 'react';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import EnhancedTable from '../../../components/common/Table';
import empty from '../../../assets/images/empty.png';
import { ROLES } from '../../../utils/constants';
import ProjectApi from '../project-api';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import Alert from '../../../components/common/Alert';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import { useHistory } from 'react-router-dom';
import UsersAPI from '../../UserContainer/user-api';
import TableWrapper from '../../../components/common/Table/tableWrapper';
import Button from '../../../components/common/Button';
import ConfirmationModal from '../../ClientContainer/statusModal';

const ACTIONS = {
  INACTIVE: 'inactive',
  DELETED: 'delete',
  REJECT: 'reject',
  ACTIVATE: 'activate',
};
const MentorsTable = ({ project }) => {
  const history = useHistory();
  const [mentors, setMentors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [clientId, setClientId] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    message: '',
    title: '',
  });
  //add mentorId and action
  const [mentorSelected, setMentorSelected] = useState();
  //allows to select users for rejecting or acceptin applicants
  const [usersSelected, setUsersSelected] = useState([]);
  const onClick = (id) => {
    setIsMenuOpen((prev) => {
      return { id, open: !prev.open };
    });
  };

  useEffect(() => {
    if (isLoading) {
      (async () => {
        try {
          const {
            data: { mentors, clientId },
          } = await ProjectApi.fetchUserByProject(
            project.announcementId,
            ROLES.Mentor
          );
          setClientId(clientId);
          setMentors(mentors);
        } catch (error) {
          setError(error.data.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isLoading]);

  //ACTIONS
  const handleActivation = async ({ mentorId, status }) => {
    try {
      //rejected is obligatory in backend
      await UsersAPI.updateUser(mentorId, {
        status,
      });
    } catch (error) {
      setError(error.data.message);
    } finally {
      setIsModalOpen({ open: false, message: '', title: '' });
      setIsLoading(true);
    }
  };

  //throws action selected once modal button is "yes"
  const onAccept = (action, mentorId) => {
    setIsMenuOpen({ open: false, id: '' });
    switch (action) {
      case ACTIONS.INACTIVE:
        handleActivation({ mentorId, status: 'deactivated' });
        break;

      case ACTIONS.REJECT:
        handleActivation({ mentorId, status: 'rejected' });
        break;
      case ACTIONS.ACTIVATE:
        handleActivation({ mentorId, status: 'active' });
      default:
        break;
    }
  };

  //used to Open modal and set action selected in menu
  const handleChangeOfStatus = (action, mentorId) => {
    switch (action) {
      case ACTIONS.INACTIVE:
        setMentorSelected({ mentor: mentorId, action: ACTIONS.INACTIVE });
        setIsModalOpen({
          open: true,
          message:
            'el usuario se inactivará, no podrá acceder a la plataforma o asistir a reuniones. ¿Estás seguro de inactivarlo?',
          titulo: 'Inactivar mentor',
        });

        break;

      case ACTIONS.REJECT:
        setMentorSelected({ mentor: mentorId, action: ACTIONS.REJECT });
        setIsModalOpen({
          open: true,
          message:
            'El mentor será marcado como rechazado. ¿Estás seguro de rechazarlo?',
          titulo: 'Rechazar mentor',
        });

        break;
      case ACTIONS.ACTIVATE:
        setMentorSelected({ mentor: mentorId, action: ACTIONS.ACTIVATE });
        setIsModalOpen({
          open: true,
          message: '¿Estás seguro de activar al mentor?',
          titulo: 'Activar mentor',
        });
        break;

      default:
        break;
    }
  };

  const renderStatus = (status) => {
    if (status === 'Rechazado') {
      return (
        <StatusIndicator
          text="Mentor no aprobado"
          statusClassName={STATUS_CLASSNAMES.REJECTED}
          error
        />
      );
    } else if (status === 'deactivated') {
      return (
        <StatusIndicator
          text="Desactivado"
          statusClassName={STATUS_CLASSNAMES.INACTIVE}
        />
      );
    } else {
      const className =
        status == 'Activo'
          ? STATUS_CLASSNAMES.ACTIVE
          : STATUS_CLASSNAMES.INACTIVE;
      return <StatusIndicator text={status} statusClassName={className} />;
    }
  };
  const renderSignupState = (step) => {
    if (step === 0) {
      return 'Registro completo';
    } else if (step === 1) {
      return 'Registro Inicial';
    } else {
      return 'Registro Incompleto';
    }
  };

  const onClickApplication = async (isAccepted, users = []) => {
    try {
      const data = await ProjectApi.updateUserApplication(
        project.announcementId,
        { isAccepted, users }
      );
      setUsersSelected([]);
      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const pdfDownload = async (id) => {
    try {
      const { data } = await UsersAPI.fetchPdfUser(id);
      window.open(data.link, '_blank');
    } catch (error) {
      console.error('Error fetching PDF data:', error.message);
      setError('Ocurrio un error al descargar el PDF');
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'E-mail', accessor: 'email' },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }) => renderStatus(value),
      },
      {
        Header: 'Estado Registro',
        Cell: ({ row: { original } }) => renderSignupState(original.registro),
      },
      {
        Header: 'Estado postulacion',
        accessor: 'isAccepted',
      },
      {
        Header: 'Pilar',
        accessor: 'pillar',
      },
      {
        Header: 'Acción',
        accessor: 'id',
        Cell: ({ value, row: { original } }) => (
          <MenuContainer onClick={() => onClick(value)}>
            {isMenuOpen.id == value && (
              <Menu
                open={isMenuOpen.open}
                items={[
                  {
                    text: 'Editar',
                    action: () =>
                      history.push('/usuarios/editar', {
                        idUser: value,
                        roleType: ROLES.Mentor,
                      }),
                  },
                  {
                    text: 'Aceptar',
                    action: () => {
                      onClickApplication(true, [original.id]);
                    },
                    status:
                      original.isAccepted === 'Aceptado'
                        ? 'disabled'
                        : 'featured',
                  },
                  {
                    text: 'Rechazar Postulación',
                    action: () => onClickApplication(false, [original.id]),
                    status:
                      original.isAccepted === 'Rechazado'
                        ? 'disabled'
                        : 'warning',
                  },
                  {
                    text:
                      original.status === 'Activo' ? 'Desactivar' : 'Activar',
                    action: () =>
                      original.status === 'Activo'
                        ? handleChangeOfStatus(ACTIONS.INACTIVE, value)
                        : handleChangeOfStatus(ACTIONS.ACTIVATE, value),
                  },
                  //rechaza al usuario de entrar a la plataforma
                  {
                    text: 'Rechazar',
                    action: () => handleChangeOfStatus(ACTIONS.REJECT, value),
                    status: 'warning',
                  },
                  {
                    text: 'Descargar PDF',
                    action: () => pdfDownload(original.id),
                  },
                ]}
              />
            )}
          </MenuContainer>
        ),
      },
    ],
    [isMenuOpen, usersSelected]
  );

  return (
    <div>
      {error && (
        <div>
          <Alert type="error">{error}</Alert>
        </div>
      )}
      {isLoading || mentors.length ? (
        <TableWrapper
          data={mentors}
          columns={columns}
          isLoadingData={isLoading}
          tableName="Mentores"
        >
          {/* <Button
            className="actions-table__button button"
            type={'button'}
            onClick={() => onClickApplication(true, usersSelected)}
          >
            Aceptar
          </Button>*/}
          <Button
            className="actions-table__button button"
            type="button"
            onClick={() => {
              console.log(project);
              history.push('/convocatorias/agregar', {
                clientId,
                role: 'mentor',
                announcementId: project.announcementId,
              });
            }}
          >
            <span className="material-icons">add</span>
            Nuevo Participante
          </Button>
        </TableWrapper>
      ) : (
        <div className="empty-tab">
          <EmptyCard
            message="Actualmente no existen mentores registrados"
            img={empty}
          >
            <Button
              className="actions-table__button button"
              type="button"
              onClick={() => {
                history.push('/convocatorias/agregar', {
                  clientId,
                  role: 'mentor',
                  announcementId: project.announcementId,
                });
              }}
            >
              <span className="material-symbols-outlined">add</span>
              {'Crear nuevo usuario'}
            </Button>
          </EmptyCard>
        </div>
      )}
      <ConfirmationModal
        title={isModalOpen?.message}
        open={isModalOpen.open}
        header={isModalOpen?.titulo}
        handleDenied={() =>
          setIsModalOpen({ open: false, message: '', title: '' })
        }
        handleAccept={() => {
          onAccept(mentorSelected.action, mentorSelected.mentor);
        }}
        onClose={() => setIsModalOpen({ open: false, message: '', title: '' })}
      ></ConfirmationModal>
    </div>
  );
};

export default MentorsTable;
