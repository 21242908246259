import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  SupervisedUserCircleOutlined,
  PersonOutlineOutlined,
  VideoCall,
  LocalOfferOutlined,
  EventAvailable,
  SettingsSharp,
  GetApp,
  Equalizer,
  Assignment,
  MailOutlineOutlined,
  LocalActivity,
  BusinessCenterOutlined,
  BallotOutlined,
} from '@material-ui/icons';
import converge from '../../../assets/images/converge.png';
import { AppContext } from '../../../app/AppContext';
import { Tooltip } from '@material-ui/core';

const Menu = () => {
  const location = useLocation();
  const { user } = useContext(AppContext);

  const pathName = location.pathname.split('/')[1];

  return (
    <div className="component-menu__container">
      <div
        // style={{ backgroundImage: `url(${converge})` }}
        className="converge-image"
      >
        <img src={converge} alt="logo" />
      </div>
      <ul>
        <li className={`${pathName === 'dashboard' ? 'active' : ''}`}>
          <Tooltip title="dashboard">
            <Link to="/dashboard">
              <Equalizer />
              <span className="menu-text">Dashboard</span>
            </Link>
          </Tooltip>
        </li>
        <li className={`${pathName === 'reuniones' ? 'active' : ''}`}>
          <Tooltip title="Reuniones">
            <Link to="/reuniones">
              <VideoCall />
              <span className="menu-text">Reuniones</span>
            </Link>
          </Tooltip>
        </li>
        <li className={`${pathName === 'formacion' ? 'active' : ''}`}>
          <Tooltip title="Formación">
            <Link to="/formacion">
              <span className="material-symbols-outlined">school</span>
              <span className="menu-text">Formación</span>
            </Link>
          </Tooltip>
        </li>
        <li className={`${pathName === 'usuarios' ? 'active' : ''}`}>
          <Tooltip title="Usuarios">
            <Link to="/usuarios">
              <SupervisedUserCircleOutlined />
              <span className="menu-text">Usuarios</span>
            </Link>
          </Tooltip>
        </li>
        <li
          className={`${
            pathName === 'clientes-y-convocatorias' ? 'active' : ''
          }`}
        >
          <Tooltip title="Convocatorias">
            <Link to="/clientes-y-convocatorias">
              <BusinessCenterOutlined />
              <span className="menu-text">Convocatorias</span>
            </Link>
          </Tooltip>
        </li>
        <li className={`${pathName === 'mentores' ? 'active' : ''}`}>
          <Tooltip title="Mentores">
            <Link to="/mentores">
              <PersonOutlineOutlined />
              <span className="menu-text">Mentores</span>
            </Link>
          </Tooltip>
        </li>
        {console.log('pathname', pathName)}
        <li className={`${pathName === 'clientes' ? 'active' : ''}`}>
          <Tooltip title="Clientes">
            <Link to="/clientes">
              <BusinessCenterOutlined />
              <span className="menu-text">Clientes</span>
            </Link>
          </Tooltip>
        </li>
        {user && user.role && user.role.type !== 'manager' && (
          <>
            <Tooltip title="Solicitudes">
              <li className={`${pathName === 'solicitudes' ? 'active' : ''}`}>
                <Link to="/solicitudes">
                  <Assignment />
                  <span className="menu-text">Solicitudes</span>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Pilares">
              <li className={`${pathName === 'pilares' ? 'active' : ''}`}>
                <Link to="/pilares">
                  <LocalOfferOutlined />
                  <span className="menu-text">Pilares</span>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Cursos">
              <li className={`${pathName === 'cursos' ? 'active' : ''}`}>
                <Link to="/cursos">
                  <EventAvailable />
                  <span className="menu-text">Cursos</span>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Encuestas">
              <li className={`${pathName === 'encuestas' ? 'active' : ''}`}>
                <Link to="/encuestas">
                  <BallotOutlined />
                  <span className="menu-text">Encuestas</span>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Beneficios">
              <li className={`${pathName === 'beneficios' ? 'active' : ''}`}>
                <Link to="/beneficios">
                  <LocalActivity />
                  <span className="menu-text">Beneficios</span>
                </Link>
              </li>
            </Tooltip>
          </>
        )}
        <Tooltip title="Novedades">
          <li className={`${pathName === 'novedades' ? 'active' : ''}`}>
            <Link to="/novedades">
              <span className="material-symbols-outlined icon">feed</span>
              <span className="menu-text">Novedades</span>
            </Link>
          </li>
        </Tooltip>
        <Tooltip title="Reportes">
          <li className={`${pathName === 'reportes' ? 'active' : ''}`}>
            <Link to="/reportes">
              <GetApp />
              <span className="menu-text">Reportes</span>
            </Link>
          </li>
        </Tooltip>
        <Tooltip title="Correos masivos">
          <li className={`${pathName === 'correos' ? 'active' : ''}`}>
            <Link to="/correos">
              <MailOutlineOutlined />
              <span className="menu-text">Correos Masivos</span>
            </Link>
          </li>
        </Tooltip>
        {user && user.role && user.role.type !== 'manager' && (
          <Tooltip title="Configuraciones">
            <li className={`${pathName === 'configuraciones' ? 'active' : ''}`}>
              <Link to="/configuraciones">
                <SettingsSharp />
                <span className="menu-text">Configuraciones</span>
              </Link>
            </li>
          </Tooltip>
        )}
        {/* <li
          className={`${
            pathName === 'encuestas' ? 'active' : ''
          }`}
        >
          <SpeakerNotes />
          <Link to="/encuestas">
            <span className="menu-text">Encuestas</span>
          </Link>
        </li>
        <li
          className={`${
            pathName === 'informes' && 'active'
          }`}
        >
          <InsertChartOutlined />
          <Link to="/informes">
            <span className="menu-text">Informes</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Menu;
