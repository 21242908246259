import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import classnames from 'classnames';

const Button = ({
  secondary,
  children,
  status,
  onClick,
  type,
  widthFull,
  disabled,
  loading,
  style,
  ...props
}) => {
  return status === 'disable' ? (
    <div className="button disable">{children}</div>
  ) : (
    <button
      type={type}
      onClick={onClick}
      className={classnames('button', {
        secondary: secondary,
        primary: !secondary,
        'w-full': widthFull,
        disabled: disabled,
      })}
      style={style}
    >
      {loading ? (
        <Loader type="ThreeDots" color={secondary ? '#0bb5e7' : '#FFFFFF'} />
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  onClick: () => {},
  secondary: false,
  widthFull: false,
  loading: false,
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['submit', 'button']),
  status: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  secondary: PropTypes.bool,
  widthFull: PropTypes.bool,
};

export default Button;
