// create a react component that will display the summary of the meeting
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Typography, Table, TableContainer, TableCell, TableHead, TableBody, TableRow, Divider } from '@material-ui/core';
import SessionAPI from '../session-api';

export default function MeetReport( { sessionId }) {
  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    SessionAPI.getParticipantsBySession(sessionId)
      .then((response) => {
        console.log(response);
        setParticipants(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sessionId]);

  function FormatDate({time}) {
    const date = new Date(time);
    return <span>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>Reporte de la reunión</Typography>
          <Divider />
          <Typography style={{ padding: '16px'}}>
            Esta sección solo muestra contenido para reuniones realizadas en Google Meet.
          </Typography>

          {participants.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Participante</TableCell>
                    <TableCell>Hora de entrada</TableCell>
                    <TableCell>Hora de salida</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.map((participant, index) => (
                    <TableRow key={index}>
                      <TableCell>{participant.name}</TableCell>
                      <TableCell><FormatDate time={participant.startTime} /></TableCell>
                      <TableCell><FormatDate time={participant.endTime} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography style={{ padding: '16px'}}>No hay participantes para mostrar.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

