import React, { useEffect, useState } from 'react';
import ClientTableContainer from './ClientTable';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/common/Button';
import ClientsApi from '../client-api';
import LinkComponent from '../../../components/common/Link';

const ClientContainer = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { clients },
      } = await ClientsApi.fetchClients();
      const clientsWithStatus = clients.map((client) => {
        return { ...client, status: client.status ? 'Activo' : 'Inactivo' };
      });
      setClients(clientsWithStatus);
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="user-container">
      <LinkComponent
        title="Volver"
        url="/clientes-y-convocatorias"
        icon="arrow_back"
      ></LinkComponent>
      <div className="content-panel">
        <div className="client__action-container">
          <span className="subtitle">
            Detalle de clientes registrados en la plataforma
          </span>
          <Button
            type="button"
            primary
            style={{ width: '220px', fontSize: '16px' }}
            onClick={() => history.push('/clientes/crear')}
          >
            <span className="material-icons">add</span>
            Nuevo cliente
          </Button>
        </div>
        <ClientTableContainer isLoading={isLoading} clients={clients} />
      </div>
    </div>
  );
};

export default ClientContainer;
